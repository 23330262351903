import { AxiosError } from "axios";
import { Form, Formik, FieldArray } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";

interface TimeInterval {
  start: string;
  end: string;
}

interface Availability {
  is_available: boolean;
  time_intervals: TimeInterval[];
}

interface VetObj {
  name: string;
  vet_id: string;
  availability: { [key: string]: Availability };
  consultation_fee: {
    currency: string;
    fee: number;
  };
}

const UpdateAvailabilityAndFee = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState<VetObj | null>(null);

  const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  useEffect(() => {
    const fetchVetData = async () => {
      try {
        const response = await axiosInstance.get("/veterinary/me");
        const vetData = response.data.data;

        // Transforming API response to match Formik structure
        const formattedData: VetObj = {
          name: vetData.name,
          vet_id: vetData.vet_id,
          availability: daysOfWeek.reduce((acc, day) => {
            const key = day.toLowerCase();
            acc[key] = vetData.availability[key] || { is_available: false, time_intervals: [] };
            return acc;
          }, {} as { [key: string]: Availability }),
          consultation_fee: vetData.consultation_fee,
        };

        setFormData(formattedData);
      } catch (error) {
        dispatch(addToast({ kind: ERROR, msg: "Failed to fetch veterinary details" }));
      }
    };

    fetchVetData();
  }, [dispatch]);

  const validationSchema = Yup.object({
    consultation_fee: Yup.object().shape({
      currency: Yup.string().required("Currency is required"),
      fee: Yup.number().min(0, "Fee must be a positive number").required("Fee is required"),
    }),
    availability: Yup.object().shape(
      Object.fromEntries(
        daysOfWeek.map((day) => [
          day.toLowerCase(),
          Yup.object().shape({
            is_available: Yup.boolean(),
            time_intervals: Yup.array().of(
              Yup.object().shape({
                start: Yup.string().required("Start time is required"),
                end: Yup.string().required("End time is required"),
              })
            ),
          }),
        ])
      )
    ),
  });

  const handleSubmit = useCallback(async (vet: VetObj) => {
    setIsSubmitting(true);
    try {
      await axiosInstance.patch(
        "/veterinary/update/me",
        { ...vet },
        { headers: { ContentType: "application/json" } }
      );

      dispatch(addToast({ kind: SUCCESS, msg: "Updated Successfully!" }));
      dispatch(hideModal());
    } catch (error) {
        dispatch(addToast({ kind: ERROR, msg: "Oops, something went wrong" }));
     
    } finally {
      setIsSubmitting(false);
    }
  }, []);

  if (!formData) {
    return <p>Loading...</p>;
  }

  return (
    <Formik initialValues={formData} validationSchema={validationSchema} onSubmit={(values) => handleSubmit(values)}>
      {({ values, setFieldValue }) => (
        <Form className="px-6 py-4 mt-2" style={{ minWidth: "360px", maxWidth: "760px" }}>
          <Input label="Consultation Fee (INR)" name="consultation_fee.fee" id="consultation_fee.fee" type="number" />

          <h3 className="text-lg font-semibold mt-4">Availability</h3>
          {daysOfWeek.map((day) => {
            const key = day.toLowerCase();
            return (
              <div key={day} className="mb-4">
                <label>
                  <input
                    type="checkbox"
                    checked={values.availability[key]?.is_available}
                    onChange={(e) =>
                      setFieldValue(`availability.${key}.is_available`, e.target.checked)
                    }
                  />{" "}
                  {day}
                </label>

                {values.availability[key]?.is_available && (
                  <FieldArray name={`availability.${key}.time_intervals`}>
                    {({ push, remove }) => (
                      <div>
                        {values.availability[key]?.time_intervals?.map((interval, index) => (
                          <div key={index} className="flex space-x-2 items-center">
                            <Input
                              label="Start"
                              name={`availability.${key}.time_intervals.${index}.start`}
                              id={`availability.${key}.time_intervals.${index}.start`}
                              type="time"
                            />
                            <Input
                              label="End"
                              name={`availability.${key}.time_intervals.${index}.end`}
                              id={`availability.${key}.time_intervals.${index}.end`}
                              type="time"
                            />
                            <button
                              type="button"
                              onClick={() => remove(index)}
                              className="text-red-500"
                            >
                              Remove
                            </button>
                          </div>
                        ))}
                        <button
                          type="button"
                          onClick={() => push({ start: "", end: "" })}
                          className="mt-2 text-blue-500"
                        >
                          + Add Time Slot
                        </button>
                      </div>
                    )}
                  </FieldArray>
                )}
              </div>
            );
          })}

          <div className="buttons flex items-center w-full justify-center my-4">
            <SubmitBtn text="Save" isSubmitting={isSubmitting} classes="text-sm" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateAvailabilityAndFee;
