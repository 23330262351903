import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import {
  ADD_SPEC_MODAL,
  ADD_SUBS_MODAL,
  ERROR,
  SUCCESS,
} from "../../types/constants";
import { MdAdd } from "react-icons/md";
import { addToast } from "../../redux/features/toastSlice";
import { AxiosError } from "axios";

interface SubscriptionObj {
  amount: number;
  discount: number;
  scans_count: number;
  currency: "INR";
  subscription_name: string;
}

const Subscriptions = () => {
  const queryClient = useQueryClient();

  const ALL_USERS_COLUMNS = [
    
    {
      Header: "Subscription Title",
      accessor: "subscription_name",
    },
    {
      Header: "Amount",
      accessor: "amount",
    },
    {
      Header: "Discount",
      accessor: "discount",
    },
    {
      Header: "Scans Count",
      accessor: "scans_count",
    }
  ];

  const dispatch = useDispatch();

  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);

  let component = null;

  const getSubscriptions = async (queryKey: string) => {
    const { data } = await axiosInstance.get(
      `/admin/subscription/all?is_active=true`
    );

   
      return data?.data;
    
    // return data?.data;
  };

  const { data, error, isLoading } = useQuery<
    SubscriptionObj[] | undefined,
    any,
    SubscriptionObj[],
    any[]
  >(["getSubscriptions", pageIndex, cPageSize, cSortBy, desc, q], () =>
    getSubscriptions(q)
  );

  if (error) {
    component = (
      <p className="mt-6 ml-4 text-center">
        An error has occurred: {error.message}
      </p>
    );
  } else if (isLoading) {
    component = <p className="mt-6 ml-4 text-center">Loading...</p>;
  } else {
    component = (
      <TableInstance
        tableData={data || []}
        columnName={ALL_USERS_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1} // do calculation here
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={true}
      />
    );
  }

  return (
    <div className="all-users">
      <header className="flex items-center justify-between mb-8">
        <div className="left">
          <SearchBox
            searchValue={q}
            setSearchValue={setQ}
            handleSearch={() => {}}
          />
        </div>

        <div className="right">
          <button
            className="btn-primary flex items-center"
            onClick={() => {
              dispatch(
                showModal({
                  modalType: ADD_SUBS_MODAL,
                  modalTitle: "Add Subscription",
                })
              );
            }}
          >
            <span className="mr-1">Add</span> <MdAdd size={17} />
          </button>
        </div>
      </header>

      {component !== null && component}
    </div>
  );
};

export default Subscriptions;
