import React, { useState } from "react";
import { useQuery } from "react-query";
import axiosInstance from "../../axiosInstance";
import { FaEye } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { showModal } from "../../redux/features/modalSlice";
import { UPDATE_APPOINTMENT_MODAL } from "../../types/constants";


interface ConsultationFee {
    fee: number;
}

interface VetProfile {
    consultation_fee: ConsultationFee;
    proof_url: any;
    vet_id: string;
    name: string;
    email: string;
    phone: string;
    vet_bio: string;
    has_clinic: boolean;
    experience: number;
    profile_picture_url: string | null;
    created_at: string;
    updated_at: string;
    specialization_id: number;
    availability: object;
    approved: boolean;
    ac_name: string;
    ac_no: string;
    ac_type: string;
    branch_name_address: string;
    ifsc_code: string;
    upi_id: string;
    gst: string;
    pan: string;
    specialization_name: string;
    veterinary_appointments: Array<null>;
    addresses: Array<{
        address_id: string;
        label: string;
        address_line: string;
        city: string;
        state: string;
        pincode: string;
        proof_url: string | null;
        coordinates: string;
        created_at: string;
        updated_at: string;
    }>;
}

const Profile = () => {
    const dispatch = useDispatch();
    const [isImageOpen, setIsImageOpen] = useState(false);

    const { data, error, isLoading } = useQuery<VetProfile>(
        "getVetProfile",
        async () => {
            const response = await axiosInstance.get("/veterinary/me");
            return response.data.data;
        }
    );

    if (isLoading) return <div className="p-4">Loading...</div>;
    if (error) return <div className="p-4 text-red-500">Error: {(error as any).message}</div>;

    return (
        <div className="p-6 max-w-7xl mx-auto">
            <h1 className="text-3xl font-bold mb-8 text-gray-800">Veterinary Profile</h1>

            {/* Profile Picture Section
            <div className="mb-10">
                <div className="relative inline-block">
                    <div className="relative group">
                        <div
                            className="w-32 h-32 rounded-full bg-gray-200 flex items-center justify-center cursor-pointer overflow-hidden"
                            onClick={() => data?.profile_picture_url && setIsImageOpen(true)}
                        >
                            {data?.profile_picture_url ? (
                                <img
                                    src={data.profile_picture_url}
                                    alt="Profile"
                                    className="w-full h-full object-cover"
                                />
                            ) : (
                                <span className="text-gray-500">No Image</span>
                            )}
                        </div>
                        {data?.profile_picture_url && (
                            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center rounded-full opacity-0 group-hover:opacity-100 transition-opacity">
                                <FaEye className="text-white text-2xl" />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {isImageOpen && data?.profile_picture_url && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
                    <div className="relative bg-white rounded-xl shadow-lg max-w-2xl w-full p-4">
                        <button
                            className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
                            onClick={() => setIsImageOpen(false)}
                        >
                            <FiX size={24} />
                        </button>
                        <img
                            src={data.profile_picture_url}
                            alt="Profile Preview"
                            className="w-full h-auto rounded-lg"
                        />
                    </div>
                </div>
            )} */}

             <button
                        className="btn-primary flex items-center justify-center w-full"
                        type="button"
                        onClick={() => {
                          dispatch(
                            showModal({
                              modalType: UPDATE_APPOINTMENT_MODAL,
                              modalTitle: "Update Details",
                            })
                          );
                        }}
                      >
                        <span className="mr-1">Update Availability & Consultation Fee</span> 
                      </button>

            <div className="space-y-10">
                {/* Personal Information */}
                <div>
                    <h2 className="text-xl font-bold mb-4 text-yellow-600 flex items-center w-full py-5">
                        <span className="flex-1 h-0.5 bg-gradient-to-l from-yellow-600 to-transparent"></span>
                        <span className="px-4 text-yellow-600">Personal Information</span>
                        <span className="flex-1 h-0.5 bg-gradient-to-r from-yellow-600 to-transparent"></span>
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-10">
                        <div>
                            <label className="block text-md font-semibold text-gray-700">Vet Id</label>
                            <input
                                value={data?.vet_id || "N/A"}
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                readOnly
                            />
                        </div>
                        <div>
                            <label className="block text-md font-semibold text-gray-700">Name</label>
                            <input
                                value={data?.name || "N/A"}
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                readOnly
                            />
                        </div>
                        <div>
                            <label className="block text-md font-semibold text-gray-700">Email</label>
                            <input
                                value={data?.email || "N/A"}
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                readOnly
                            />
                        </div>
                        <div>
                            <label className="block text-md font-semibold text-gray-700">Phone</label>
                            <input
                                value={data?.phone || "N/A"}
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                readOnly
                            />
                        </div>
                        <div>
                            <label className="block text-md font-semibold text-gray-700">Specialization Id</label>
                            <input
                                value={data?.specialization_id || "N/A"}
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                readOnly
                            />
                        </div>
                        <div>
                            <label className="block text-md font-semibold text-gray-700">Experience</label>
                            <input
                                value={`${data?.experience ?? "N/A"} months`}
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                readOnly
                            />
                        </div>
                        <div>
                            <label className="block text-md font-semibold text-gray-700">Approval Status</label>
                            <input
                                value={data?.approved ? "Approved" : "Pending"}
                                className={`mt-1 block w-full rounded-2xl shadow-sm px-5 py-3 font-semibold ${data?.approved ? "green-700 bg-green-100" : "text-green-700 bg-green-100"
                                    }`}
                                readOnly
                            />
                        </div>
                        <div>
                            <label className="block text-md font-semibold text-gray-700">Has Clinic</label>
                            <input
                                value={data?.has_clinic ? "True" : "False"}
                                className={`mt-1 block w-full rounded-2xl shadow-sm px-5 py-3 font-semibold ${data?.has_clinic ? "text-green-700 bg-green-100" : "text-green-700 bg-green-100"
                                    }`}
                                readOnly
                            />
                        </div>
                        <div>
                            <label className="block text-md font-semibold text-gray-700">Created At</label>
                            <div className="flex space-x-4">
                                <input
                                    value={data?.created_at ? new Date(data.created_at).toLocaleDateString("en-GB") : "N/A"}
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    readOnly
                                />
                                <input
                                    value={data?.created_at ? new Date(data.created_at).toLocaleTimeString("en-GB") : "N/A"}
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    readOnly
                                />
                            </div>
                        </div>
                        <div>
                            <label className="block text-md font-semibold text-gray-700">Updated At</label>
                            <div className="flex space-x-4">
                                <input
                                    value={data?.updated_at ? new Date(data.updated_at).toLocaleDateString("en-GB") : "N/A"}
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    readOnly
                                />
                                <input
                                    value={data?.updated_at ? new Date(data.updated_at).toLocaleTimeString("en-GB") : "N/A"}
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Professional Details */}
                <div>
                    <h2 className="text-xl font-bold mb-4 text-yellow-600 flex items-center w-full py-5">
                        <span className="flex-1 h-0.5 bg-gradient-to-l from-yellow-600 to-transparent"></span>
                        <span className="px-4 text-yellow-600">Professional Details</span>
                        <span className="flex-1 h-0.5 bg-gradient-to-r from-yellow-600 to-transparent"></span>
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-10">
                        <div>
                            <label className="block text-md font-semibold text-gray-700">Specialization</label>
                            <input
                                value={data?.specialization_name || "N/A"}
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                readOnly
                            />
                        </div>
                        <div>
                            <label className="block text-md font-semibold text-gray-700">Consultation Fee</label>
                            <input
                                value={
                                    data?.consultation_fee?.fee
                                        ? `₹${data.consultation_fee.fee}`
                                        : "Not Available"
                                }
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                readOnly
                            />
                        </div>
                        {/* <div>
                            <label className="block text-md font-semibold text-gray-700">Veterinary Appointments</label>
                            <input
                                value={
                                    !data?.veterinary_appointments ||
                                        (Array.isArray(data.veterinary_appointments) &&
                                            (data.veterinary_appointments.length === 0 || data.veterinary_appointments.every(item => item === null)))
                                        ? "Not Available"
                                        : JSON.stringify(data.veterinary_appointments)
                                }
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                readOnly
                            />
                        </div> */}
                        <div>
                            <label className="block text-md font-semibold text-gray-700">Veterinary Bio</label>
                            <textarea
                                value={data?.vet_bio || "N/A"}
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none h-32"
                                readOnly
                            />
                        </div>
                        <div>
                            <label className="block text-md font-semibold text-gray-700">GST Number</label>
                            <input
                                value={data?.gst || "N/A"}
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                readOnly
                            />
                        </div>
                        <div>
                            <label className="block text-md font-semibold text-gray-700">PAN Number</label>
                            <input
                                value={data?.pan || "N/A"}
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                readOnly
                            />
                        </div>
                    </div>
                </div>

                {/* Bank Details */}
                <div>
                    <h2 className="text-xl font-bold mb-4 text-yellow-600 flex items-center w-full py-5">
                        <span className="flex-1 h-0.5 bg-gradient-to-l from-yellow-600 to-transparent"></span>
                        <span className="px-4 text-yellow-600">Bank Details</span>
                        <span className="flex-1 h-0.5 bg-gradient-to-r from-yellow-600 to-transparent"></span>
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-10">
                        <div>
                            <label className="block text-md font-semibold text-gray-700">Account Name</label>
                            <input
                                value={data?.ac_name || "N/A"}
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                readOnly
                            />
                        </div>
                        <div>
                            <label className="block text-md font-semibold text-gray-700">Account Number</label>
                            <input
                                value={data?.ac_no || "N/A"}
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                readOnly
                            />
                        </div>
                        <div>
                            <label className="block text-md font-semibold text-gray-700">Account Type</label>
                            <input
                                value={data?.ac_type || "N/A"}
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                readOnly
                            />
                        </div>
                        <div>
                            <label className="block text-md font-semibold text-gray-700">IFSC Code</label>
                            <input
                                value={data?.ifsc_code || "N/A"}
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                readOnly
                            />
                        </div>
                        <div>
                            <label className="block text-md font-semibold text-gray-700">Branch Address</label>
                            <input
                                value={data?.branch_name_address || "N/A"}
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                readOnly
                            />
                        </div>
                        <div>
                            <label className="block text-md font-semibold text-gray-700">UPI ID</label>
                            <input
                                value={data?.upi_id || "N/A"}
                                className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                readOnly
                            />
                        </div>
                    </div>
                </div>

                {/* Clinic Address */}
                {data?.addresses?.map((address, index) => (
                    <div key={index}>
                        <h2 className="text-xl font-bold mb-4 text-yellow-600 flex items-center w-full py-5">
                            <span className="flex-1 h-0.5 bg-gradient-to-l from-yellow-600 to-transparent"></span>
                            <span className="px-4 text-yellow-600">
                                {address.label
                                    ? `${address.label.charAt(0).toUpperCase() + address.label.slice(1)} Address`
                                    : "Address"}
                            </span>
                            <span className="flex-1 h-0.5 bg-gradient-to-r from-yellow-600 to-transparent"></span>
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-10">
                            <div>
                                <label className="block text-md font-semibold text-gray-700">Label</label>
                                <input
                                    value={address.label || "N/A"}
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    readOnly
                                />
                            </div>
                            <div>
                                <label className="block text-md font-semibold text-gray-700">Proof URL</label>
                                <input
                                    // value={data?.proof_url ? data.proof_url : "Not Available"}
                                    value={address?.proof_url ? address?.proof_url : "Not Available"}
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    readOnly
                                />
                            </div>

                            <div className="pr-10">
                                <label className="block text-md font-semibold text-gray-700">Coordinates</label>
                                {/* {data?.coordinates ? ( */}
                                {address?.coordinates ? (
                                    <a
                                        href={`https://www.google.com/maps?q=${address?.coordinates.replace(/[()]/g, '')}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-blue-100 px-5 py-3 text-blue-600 font-semibold text-center"
                                    >
                                        Open Location
                                    </a>
                                ) : (
                                    <div className="mt-1 block w-full rounded-2xl shadow-sm bg-red-100 px-5 py-3 text-red-600 font-semibold text-center">
                                        No Coordinates Available
                                    </div>
                                )}
                            </div>


                            <div>
                                <label className="block text-md font-semibold text-gray-700">Address Line</label>
                                <input
                                    value={address.address_line || "N/A"}
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    readOnly
                                />
                            </div>
                            <div>
                                <label className="block text-md font-semibold text-gray-700">City</label>
                                <input
                                    value={address.city || "N/A"}
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    readOnly
                                />
                            </div>
                            <div>
                                <label className="block text-md font-semibold text-gray-700">State</label>
                                <input
                                    value={address.state || "N/A"}
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    readOnly
                                />
                            </div>
                            <div>
                                <label className="block text-md font-semibold text-gray-700">Pincode</label>
                                <input
                                    value={address.pincode || "N/A"}
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    readOnly
                                />
                            </div>
                            <div>
                                <label className="block text-md font-semibold text-gray-700">Coordinates</label>
                                <input
                                    value={address.coordinates || "N/A"}
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    readOnly
                                />
                            </div>
                            <div>
                                <label className="block text-md font-semibold text-gray-700">Created At</label>
                                <div className="flex space-x-4">
                                    <input
                                        value={address?.created_at ? new Date(address.created_at).toLocaleDateString("en-GB") : "N/A"}
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        readOnly
                                    />
                                    <input
                                        value={address?.created_at ? new Date(address.created_at).toLocaleTimeString("en-GB") : "N/A"}
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div>
                                <label className="block text-md font-semibold text-gray-700">Updated At</label>
                                <div className="flex space-x-4">
                                    <input
                                        value={address?.updated_at ? new Date(address.updated_at).toLocaleDateString("en-GB") : "N/A"}
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        readOnly
                                    />
                                    <input
                                        value={address?.updated_at ? new Date(address.updated_at).toLocaleTimeString("en-GB") : "N/A"}
                                        className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

                {/* System Information
                <div>
                    <h2 className="text-xl font-bold mb-4 text-yellow-600 flex items-center w-full py-5">
                        <span className="flex-1 h-0.5 bg-gradient-to-l from-yellow-600 to-transparent"></span>
                        <span className="px-4 text-yellow-600">System Information</span>
                        <span className="flex-1 h-0.5 bg-gradient-to-r from-yellow-600 to-transparent"></span>
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-10">
                        <div>
                            <label className="block text-md font-semibold text-gray-700">Created At</label>
                            <div className="flex space-x-4">
                                <input
                                    value={data?.created_at ? new Date(data.created_at).toLocaleDateString("en-GB") : "N/A"}
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    readOnly
                                />
                                <input
                                    value={data?.created_at ? new Date(data.created_at).toLocaleTimeString("en-GB") : "N/A"}
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    readOnly
                                />
                            </div>
                        </div>
                        <div>
                            <label className="block text-md font-semibold text-gray-700">Updated At</label>
                            <div className="flex space-x-4">
                                <input
                                    value={data?.updated_at ? new Date(data.updated_at).toLocaleDateString("en-GB") : "N/A"}
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    readOnly
                                />
                                <input
                                    value={data?.updated_at ? new Date(data.updated_at).toLocaleTimeString("en-GB") : "N/A"}
                                    className="mt-1 block w-full rounded-2xl shadow-sm bg-gray-200 px-5 py-3 outline-none border-none"
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default Profile;