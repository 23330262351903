import { AxiosError } from "axios";
import { Form, Formik, FieldArray } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import Select from "../FormikComponents/Select";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import CenterMarker from "../MapsComponents/CenterMarker";

interface Availability {
  is_available: boolean;
  start: string;
  end: string;
}

interface VetObj {
  name: string;
  phone: string;
  email: string;
  password: string;
  cpassword?: string;
  vet_bio: string;
  has_clinic: string;
  profile_picture_url?: string;
  experience?: number;
  specialization_id?: number;
  availability: { [key: string]: Availability };
  consultation_fee: {
    currency: string;
    fee: number;
  };
  address?: {
    address_line: string;
    label: string;
    city: string;
    state: string;
    pincode: string;
    lat: number;
    lng: number;
  };
  ac_name?: string;
  ac_no?: string;
  ac_type?: string;
  branch_name_address?: string;
  ifsc_code?: string;
  upi_id?: string;
  gst?: string;
  pan?: string;
}

const AddVetModal = () => {
  const [role, setRole] = useState(localStorage.getItem("role"));

  function isAxiosError(error: any): error is AxiosError {
    return error.isAxiosError === true;
  }

  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const [specialisations, setSpecialisations] = useState<{ value: number; label: string }[]>([]);

  const clinicOptions = [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
  ];

  const accountTypeOptions = [
    { value: "Savings", label: "Savings" },
    { value: "Current", label: "Current" },
  ];

  const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  const initialValues: VetObj = {
    name: "",
    phone: "",
    email: "",
    password: "",
    cpassword: "",
    vet_bio: "",
    has_clinic: "No",
    experience: 0,
    specialization_id: undefined,
    availability: daysOfWeek.reduce((acc, day) => {
      acc[day.toLowerCase()] = { is_available: false, start: "09:00", end: "17:00" };
      return acc;
    }, {} as { [key: string]: Availability }),
    consultation_fee: { currency: "INR", fee: 0 },
    address: {
      address_line: "",
      label: "clinic",
      city: "",
      state: "",
      pincode: "",
      lat: 0.0,
      lng: 0.0,
    },
    ac_name: "",
    ac_no: "",
    ac_type: "",
    branch_name_address: "",
    ifsc_code: "",
    upi_id: "",
    gst: "",
    pan: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required").min(3, "Name must be at least 3 characters"),
    phone: Yup.string()
      .required("Phone number is required")
      .min(10, "Phone number must be 10 digits")
      .max(10, "Phone number must be 10 digits"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required").min(8, "Password must be at least 8 characters"),
    cpassword: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match"),
    vet_bio: Yup.string().required("Bio is required").min(10, "Bio must be at least 10 characters"),
    has_clinic: Yup.boolean().required("Please specify if the vet has a clinic"),
    experience: Yup.number().required("Experience is required"),
    specialization_id: Yup.number().required("Specialization is required"),
    address: Yup.object().when("has_clinic", {
      is: true,
      then: Yup.object().shape({
        address_line: Yup.string().required("Address line is required"),
        label: Yup.string().required("Address label is required"),
        city: Yup.string().required("City is required"),
        state: Yup.string().required("State is required"),
        pincode: Yup.string().required("Pincode is required"),
        lat: Yup.number().required("Latitude is required"),
        lng: Yup.number().required("Longitude is required"),
      }),
      otherwise: Yup.object().notRequired(),
    }),
    ac_name: Yup.string().required("Account name is required"),
    ac_no: Yup.string().required("Account number is required"),
    ac_type: Yup.string().required("Account type is required"),
    branch_name_address: Yup.string().required("Branch name and address are required"),
    ifsc_code: Yup.string().required("IFSC code is required"),
    upi_id: Yup.string().optional(),
    gst: Yup.string().optional(),
    pan: Yup.string().optional(),
    consultation_fee: Yup.object().shape({
      currency: Yup.string().required("Currency is required"),
      fee: Yup.number().min(0, "Fee must be a positive number").required("Fee is required"),
    }),
    availability: Yup.object().shape(
      Object.fromEntries(
        ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"].map((day) => [
          day,
          Yup.object().shape({
            is_available: Yup.boolean(),
            start: Yup.string().when("is_available", {
              is: true,
              then: Yup.string().required("Start time is required"),
            }),
            end: Yup.string().when("is_available", {
              is: true,
              then: Yup.string().required("End time is required"),
            }),
          }),
        ])
      )
    ),
  });

  useEffect(() => {
    const fetchSpecialisations = async () => {
      try {
        const response = await axiosInstance.get(`/other/specialization/all`);
        const { data } = response.data;
        const formattedSpecialisations = data.map((specialization: { specialization_id: number; name: string }) => ({
          value: specialization.specialization_id,
          label: specialization.name,
        }));
        setSpecialisations(formattedSpecialisations);
      } catch (error) {
        dispatch(addToast({ kind: ERROR, msg: "Failed to fetch specialisations" }));
      }
    };

    fetchSpecialisations();
  }, [dispatch]);

  const handleSubmit = useCallback(async (vet: VetObj) => {
    setIsSubmitting(true);

    try {
      let profilePictureUrl;
      if (profilePicture) {
        // Uncomment this line when the uploadProfilePicture function is implemented
        //profilePictureUrl = await uploadProfilePicture(profilePicture, vet.name);
      }


      delete vet.cpassword;

      await axiosInstance.post(
        role === 'admin' ? `/admin/veterinary/create` : `/veterinary/register`,
        {
          ...vet,
          phone: String(vet.phone),
          has_clinic: vet.has_clinic === "true",
          profile_picture_url: profilePictureUrl,
          address: vet.has_clinic === "true" ? vet.address : undefined,
        },
        {
          headers: {
            ContentType: "application/json",
          },
        }
      );

      queryClient.invalidateQueries(["getAllVets"]);
      dispatch(addToast({ kind: SUCCESS, msg: "Veterinary added successfully" }));
      dispatch(hideModal());
    } catch (error) {
      if ((isAxiosError(error) && error.response)) {
        const { msg } = error.response.data;
        dispatch(addToast({ kind: ERROR, msg }));
      } else {
        dispatch(addToast({ kind: ERROR, msg: "Oops, something went wrong" }));
      }
    } finally {
      setIsSubmitting(false);
    }
  }, [profilePicture]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ values }) => (
        <Form className="px-6 py-4 mt-2" style={{ minWidth: "360px", maxWidth: "760px" }}>
          <div className="flex item-center gap-x-6">
            <Input label="Name" id="name" name="name" type="text" placeholder="Name" />
            <Input label="Phone" id="phone" name="phone" type="number" placeholder="Phone" />
          </div>
          <Input label="Email" id="email" name="email" type="email" placeholder="example@gmail.com" />
          <Input label="Password" id="password" name="password" type="password" placeholder="Enter Password" />
          <Input label="Confirm Password" id="cpassword" name="cpassword" type="password" placeholder="Confirm Password" />
          <Input label="Bio" id="vet_bio" name="vet_bio" type="text" placeholder="Bio" />
          <Select label="Has Clinic?" id="has_clinic" name="has_clinic" options={clinicOptions} />
          <Input label="Experience (in months)" id="experience" name="experience" type="number" placeholder="Enter Experience in months" />
          <Select label="Specialization" id="specialization_id" name="specialization_id" options={specialisations} />
          <h3 className="text-lg font-semibold mt-4">Consultation Fee</h3>
          <Input label="Currency" name="consultation_fee.currency" id="consultation_fee.currency" type="text" />
          <Input label="Fee" name="consultation_fee.fee" id="consultation_fee.fee" type="number" />

          <h3 className="text-lg font-semibold mt-4">Availability</h3>
          {daysOfWeek.map((day) => (
            <div key={day}>
              <label>
                <input type="checkbox" name={`availability.${day.toLowerCase()}.is_available`} /> {day}
              </label>
              <Input label="Start Time" name={`availability.${day.toLowerCase()}.start`}  id={`availability.${day.toLowerCase()}.start`} type="time" />
              <Input label="End Time" name={`availability.${day.toLowerCase()}.end`} id={`availability.${day.toLowerCase()}.end`} type="time" />
            </div>
          ))}

          {values.has_clinic === "true" && (
            <>
              <h3 className="text-lg font-semibold mt-4">Address</h3>
              <Input label="Address Line" id="address.address_line" name="address.address_line" type="text" placeholder="e.g. Street No. XXX, ..." />
              <Input label="Label" id="address.label" name="address.label" type="text" placeholder="Enter Label" />
              <Input label="City" id="address.city" name="address.city" type="text" placeholder="e.g. Jaipur" />
              <Input label="State" id="address.state" name="address.state" type="text" placeholder="e.g. Delhi" />
              <Input label="Pincode" id="address.pincode" name="address.pincode" type="text" placeholder="e.g. 110201" />



              <h3 className="text-lg font-semibold mt-4">Additional Details</h3>
              <Input label="Registration Number" id="registration_number" name="registration_number" type="text" placeholder="e.g. REG123456" />
              <Input label="Clinic Name" id="clinic_name" name="clinic_name" type="text" placeholder="e.g. PetCare Clinic" />
              <Input label="Clinic Proof URL" id="clinic_proof_url" name="clinic_proof_url" type="text" placeholder="e.g. http://example.com/proof.jpg" />
              <Input label="Clinic Registration Number" id="clinic_registration_number" name="clinic_registration_number" type="text" placeholder="e.g. CLINIC12345" />

              <CenterMarker latName="address.lat" lngName="address.lng" />
            </>
          )}
          <h3 className="text-lg font-semibold mt-4">Bank Details</h3>
          <Input
            label="Account Name"
            id="ac_name"
            name="ac_name"
            type="text"
            placeholder="e.g. John Doe"
            required
          />
          <Input
            label="Account Number"
            id="ac_no"
            name="ac_no"
            type="text"
            placeholder="e.g. 1234567890"
            required
          />
          <Select
            label="Account Type"
            id="ac_type"
            name="ac_type"
            options={[
              { value: "Savings", label: "Savings" },
              { value: "Current", label: "Current" },
            ]}
          />
          <Input
            label="Branch Name & Address"
            id="branch_name_address"
            name="branch_name_address"
            type="text"
            placeholder="e.g. HDFC Bank, MG Road, Bengaluru"
            required
          />
          <Input
            label="IFSC Code"
            id="ifsc_code"
            name="ifsc_code"
            type="text"
            placeholder="e.g. HDFC0001234"
            required
          />
          <Input
            label="UPI ID"
            id="upi_id"
            name="upi_id"
            type="text"
            placeholder="e.g. john.doe@upi"
          />
          <Input
            label="GST Number"
            id="gst"
            name="gst"
            type="text"
            placeholder="e.g. 22AAAAA0000A1Z5"
          />
          <Input
            label="PAN Number"
            id="pan"
            name="pan"
            type="text"
            placeholder="e.g. ABCDE1234F"
          />

          <div className="buttons flex items-center w-full justify-center my-4">
            <SubmitBtn text="Save" isSubmitting={isSubmitting} classes="text-sm" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddVetModal;
