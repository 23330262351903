import { HiOutlineSearch } from "react-icons/hi";
import React from "react";

interface Props {
  searchValue: string;
  setSearchValue: Function;
  handleSearch: Function;
  className?: string;
}

const SearchBox = ({ searchValue, setSearchValue, handleSearch, className }: Props) => {
  return (
    <form
      className={`flex items-center mr-4 text-base bg-white border rounded-xl ${className}`}
      onSubmit={() => handleSearch()}
    >
      <input
        type="text"
        placeholder="Search"
        className={`bg-transparent outline-none flex items-center justify-center p-4`}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <button type="submit">
        <HiOutlineSearch className="icon-md mr-4" />
      </button>
    </form>
  );
};

export default SearchBox;
