import React from "react";
import { BsDiamondFill } from "react-icons/bs";
import { SUBTITLE, TITLE } from "../../config";

const Logo = () => {
  return (
    <div className="logo flex items-center">
      <div className="left mr-3">
        <img src="/roundLogo.png" alt="logo" className="w-10 h-10" />
      </div>
      <div className="right">
        <h3 className="font-bold text-xl leading-none text-white">{TITLE}</h3>
        <span className="text-xs font-semibold">{localStorage.getItem('role')?.toLocaleUpperCase()}</span>
      </div>
    </div>
  );
};

export default Logo;
