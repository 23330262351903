import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/app";
import { hideSidebar, showSidebar } from "../../redux/features/sidebarSlice";
import { HiOutlineChevronDoubleLeft, HiOutlineMenu } from "react-icons/hi";
import SideBarLink from "./SidebarLink";
import { MdDashboard, MdDateRange, MdGroup, MdList, MdMedicalServices} from "react-icons/md";
import Logo from "../Logo/Logo";

const Sidebar = () => {
  const dispatch = useDispatch();
  const { show } = useSelector((state: RootState) => state.sidebar);
  const isAdmin = localStorage.getItem("role")?.toUpperCase() === "ADMIN";
  
  return (
    <aside
      className={`sidebar z-20 shadow-xl fixed left-0 top-0 bottom-0 overflow-x-hidden box-border ${show ? "w-60" : "w-20"
        } bg-neutral-800 h-screen flex flex-col text-white transition-all`}
    >
      <header
        className={` ${show ? "justify-between" : "justify-center"
          } flex  items-center px-4 py-5 mb-2 noselect`}
      >
        {
          <div className={`${show ? "opacity-100" : "opacity-0 hidden"}`}>
            <Logo />
          </div>
        }

        {show ? (
          <button
            onClick={() => dispatch(hideSidebar())}
            className="text-white"
          >
            <HiOutlineChevronDoubleLeft size={21} />
          </button>
        ) : (
          <button
            className="text-white"
            onClick={() => dispatch(showSidebar())}
          >
            <HiOutlineMenu size={21} />
          </button>
        )}
      </header>

      <nav className="sidebar-links" id="sidebar">
        <ul>
          <SideBarLink
            to="/"
            Icon={MdDashboard}
            text="Dashboard"
            smallText="Dashboard"
            key="Dashboard"
          />
          {isAdmin && <SideBarLink
            to="/users"
            list={["/users", "/users/vets"]}
            Icon={MdGroup}
            text="Users"
            smallText="Users"
            key="Users"
          />}
          {isAdmin && <SideBarLink
            to="/specialisations"
            list={["/specialisations"]}
            Icon={MdMedicalServices}
            text="Specialisations"
            smallText="specialisations"
            key="specialisations"
          />}
          {isAdmin && <SideBarLink
            to="/subscriptions"
            list={["/subscriptions"]}
            Icon={MdList}
            text="Subscriptions"
            smallText="subscriptions"
            key="subscriptions"
          />}
          <SideBarLink
            to="/subscriptions"
            list={["/subscriptions"]}
            Icon={MdList}
            text="Subscriptions"
            smallText="subscriptions"
            key="subscriptions"
          />
          <SideBarLink
            to="/appointments"
            list={["/appointments"]}
            Icon={MdDateRange}
            text="Appointments"
            smallText="appointments"
            key="appointments"
          />

         {!isAdmin && <SideBarLink
            to="/profile"
            list={["/profile"]}
            Icon={MdDateRange}
            text="Profile"
            smallText="Profile"
            key="Profile"
          />}
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
